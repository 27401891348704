import { Router } from 'preact-router'
import { HelmetProvider } from 'react-helmet-async'

// Code-splitting is automated for `routes` directory
import Home from '../routes/home'

const App = () => (
  <HelmetProvider>
    <div className="h-full">
      <Router>
        <Home path="/" />
      </Router>
    </div>
  </HelmetProvider>
)

export default App
